import styled from 'styled-components';

const MemberBenefitStyles = styled.div`
.list__container {
  align-items: flex-start;
  display: flex; 
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  padding: 20px;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
}

.list-vendor__container {
  align-items: flex-start;
  display: flex; 
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
}

.number {
  color: ${props => props.theme.orange};
  font-size: 35px;
  font-weight: 800;
  line-height: 48px;
  padding-right: 0.5em;
}

ul {
  margin: 0;
  padding: 0 20px;
}


.li-vendor {
  flex-direction: column;
}

li {
  display: flex; 
  font-size: 16px;
  line-height: 200%;
  max-width: 440px;
  padding-bottom: 40px;
}

h6 {
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
}

a {
  color: ${props => props.theme.orange};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.vendor__container {
  background: ${props => props.theme.gray};
}

.JoinNow__container {
  align-items: center;
  background: ${props => props.theme.orange};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 20px;
}

.JoinNow__message {
  color: ${props => props.theme.white};
  font-size: 25px;
  font-weight: 800;
  line-height: 34px;
  margin-bottom: 32px;
  max-width: 768px;
  text-align: center;
}

.JoinNow__button {
    background: ${props => props.theme.white};
    border-radius: 3px;
    border: 3px solid ${props => props.theme.white};
    box-sizing: border-box;
    color: ${props => props.theme.orange};
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    padding: 13px 44px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
    z-index: 100;

    &:hover, &:active {
      background: transparent;
      color: ${props => props.theme.white};
    }
  }
`;

export default MemberBenefitStyles;
